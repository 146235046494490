import React, { useEffect, useState } from "react";
import {
    Button,
    DataTable,
    InputSelect,
    Pagination,
    SearchBox,
} from "@bluesilodev/timhutcomponents";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useGetAllCompany } from "service/companyAPI";
import { useGetAllUser } from "service/userAPI";
import TableMenu from "./TableMenu";
import DateDisplay from "./DateDisplay";
import { UserAddIcon } from "components/icons";

function SuperAdminEmployee() {
    const navigate = useNavigate();
    const inputRef = React.useRef(null);
    const [keyword, setKeyword] = useState("");
    const [company, setCompany] = useState("");
    const [paginate, setPaginate] = useState({ totalData: 0, rowsPerPage: 10, currentPage: 1 });
    const queryClient = useQueryClient();
    const { data, isSuccess, refetch } = useGetAllUser({ keyword: keyword, organizationID: company }, {
        onSuccess: () => {
            queryClient.invalidateQueries("getUser");
        },
        onError: (err) => {
            console.log("err: ", err);
        },
    });
    const { data: dataCompany, isSuccess: isSuccessCompany, refetch: refetchCompany } = useGetAllCompany({
        onSuccess: () => {
            queryClient.invalidateQueries("getCompany");
        },
        onError: (err) => {
            console.log("err: ", err);
        },
    });

    React.useEffect(() => {
        data && setPaginate((oldData) => ({ ...oldData, totalData: data?.totalDocs }))
    }, [data]);

    // FETCH DATA IF SUCCES GET API
    if (isSuccess && !data) {
        refetch();
    }

    const dataColumn = [
        {
            id: "firstName",
            accessorFn: (row) => {
                return {
                    firstName: row?.firstName || "",
                    photo: row.photo || "",
                };
            },
            cell: (param) => {
                if (param) {
                    const valueParam = param.getValue();
                    return (
                        <div className="flex gap-2 items-center">
                            <img
                                className="w-[30px] h-[30px] rounded-full"
                                src={valueParam?.photo[0]?.link}
                                alt="firstNameImg"
                            />
                            <h1>{valueParam.firstName}</h1>
                        </div>
                    );
                }
            },
            header: () => <span>Employee</span>,
            enableSorting: true,
        },
        {
            id: "employeeStatus",
            accessorFn: (row) => {
                return row?.employeeStatus && row?.employeeStatus?.employeeType;
            },
            header: () => <div>Employee status</div>,
            enableSorting: true,
        },
        {
            id: "location",
            accessorFn: (row) => {
                const data = row?.locations && row?.locations[0]?.locationName;
                return data;
            },
            header: () => <span>Location</span>,
            enableSorting: true,
        },
        {
            id: "department",
            accessorFn: (row) => {
                return row?.userInformation && row?.userInformation?.employeementDetail?.departments
            },
            header: () => <span>Department</span>,
            enableSorting: true,
        },
        {
            id: "jobPosition",
            accessorFn: (row) => {
                return (
                    row?.userInformation && row?.userInformation?.employeementDetail?.jobPosition
                );
            },
            header: () => <span>Job Position</span>,
            enableSorting: true,
        },
        {
            id: "email",
            accessorFn: (row) => row?.email,
            header: () => <span>Email</span>,
            enableSorting: true,
        },
        {
            id: "dateJoin",
            accessorFn: (row) => {
                return (
                    row?.userInformation &&
                    row?.userInformation?.employeementDetail?.joinDate
                );
            },
            cell: (param) => {
                const val = param.getValue();
                return <DateDisplay dateStr={val} />;
            },
            header: () => <span>Date Joining</span>,
            enableSorting: true,
        },
        {
            id: "contactNumber",
            accessorFn: (row) => row?.phoneNumber,
            header: () => <span>Contact Number</span>,
            enableSorting: true,
        },
        {
            id: "companyName",
            accessorFn: (row) => row?.company?.companyName,
            header: () => <span>Company</span>,
            enableSorting: true,
        },
        {
            id: "action",
            disableSorting: true,
            header: () => "",
            accessorFn: (row) => {
                return row && row;
            },
            cell: (param) => {
                if (param) {
                    const data = param.getValue();
                    return (
                        <div className="cursor-pointer w-fit">
                            <TableMenu data={data} />
                        </div>
                    );
                }
            },
        },
    ];
    return (
        <React.Fragment>
            <div>
                <div className="w-full pt-4">
                    <div className="w-full flex justify-between">
                        <SearchBox
                            className={"w-1/5 h-[60px]"}
                            value={keyword}
                            ref={inputRef}
                            onChange={(e) => setKeyword(e.target.value)}
                            placeholder="Search by name"
                        />
                        <InputSelect
                            onChange={(e) => setCompany(e.target.value)}
                            title={"Company"}
                            options={dataCompany?.data?.map((value) => ({ label: value.companyName, value: value.uId })) || []}
                            classname={"w-1/5 ml-4"}
                        />
                        {/*
                        <div className={`flex w-full gap-4`}>
                        </div>
                        */}
                        <Button
                            onClick={() => { navigate("/super/addEmployee") }}
                            style="solid"
                            className={" text-white h-full w-1/5"}
                            label={
                            <div className="flex px-4 gap-1">
                                <UserAddIcon />
                                <div>Add Employee </div>
                            </div>
                            }
                        />
                    </div>
                    <div className="pt-5">
                        <DataTable
                            title="Organization Data"
                            columns={dataColumn}
                            data={!data?.data ? [] : data?.data}
                            pagination={false}
                        />
                        <div className="py-2" />
                        <Pagination
                            totalData={paginate.totalData}
                            rowsPerPage={paginate.rowsPerPage}
                            currentPage={paginate.currentPage}
                            onChangeCurrentPage={(val) => setPaginate((oldData) => ({ ...oldData, currentPage: val + 1 }))}
                            onChangeRowsPerPage={(val) => setPaginate((oldData) => ({ ...oldData, rowsPerPage: val }))}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default SuperAdminEmployee;
