import { useQuery, useMutation } from '@tanstack/react-query'
import { customAxios } from "utils/axios";

const API = `/api/employeeStatus`;

export const useSuperAdminGetAllEmployeeStatus = (organizationID, option) => {
    return useQuery({
        queryKey: ["getEmployeeStatus", organizationID],
        queryFn: async () => {
            const res = await customAxios.get(`${API}/superAdmin/${organizationID}`);
            return res.data;
        },
        ...option
    });
}

export const useGetAllEmployeeStatus = (keyword, option) => {
    return useQuery({
        queryKey: ["getEmployeeStatus", keyword],
        queryFn: async () => {
            const res = await customAxios.get(`${API}?keyword=${keyword}`);
            return res.data;
        },
        ...option
    });
}

export const useGetEmployeeStatusById = (uId, { onSuccess, onError }) => {
    return useQuery({
        queryKey: ["getEmployeeStatusById", uId],
        queryFn: async () => {
            const res = await customAxios.get(`${API}/${uId}/`)

            return res.data;
        },
        onSuccess,
        onError
    })
}

export const useCreateEmployeeStatus = ({ onSuccess, onError }) => {
    return useMutation({
        mutationFn: async (body) => {
            const res = await customAxios.post(`${API}`, body)

            return res.data;
        },
        onSuccess,
        onError,
    });
};

export const useDeleteEmployeeStatus = (uId, { onSuccess, onError }) => {
    return useMutation({
        mutationFn: async () => {
            const res = await customAxios.delete(`${API}/${uId}`)

            return res;
        },
        onSuccess,
        onError
    });
};

export const useEditEmployeeStatus = (uId, { onSuccess, onError }) => {
    return useMutation({
        mutationFn: async (body) => {

            const res = await customAxios.put(`${API}/${uId}`, body)

            return res.data;
        },
        onSuccess,
        onError
    });
};

