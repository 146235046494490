import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { useGetLocationById, useEditLocation } from "service/locationAPI";
import { alertSuccess, alertError } from "utils/alert";

import FormSharedLocation from "pages/shared/formLocation";
import { editLocationSchema } from "./editLocationSchema";

function EditLocation() {
  const { uId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data, isPending } = useGetLocationById(uId, {
    onSuccess: () => {
      queryClient.invalidateQueries("getLocationById");
    },
  });

  const { mutate: updateLocation, isPending: pendingLocation } =
    useEditLocation(uId, {
      onSuccess: () => {
        queryClient.invalidateQueries("getLocationById");
        alertSuccess("SUCCESS UPDATE DATA LOCATION");
        navigate("/location");
      },
      onError: () => {
        alertError("UPDATE DATA FAILED!");
      },
    });

  const initialValues = {
    locationName: "",
    country: "",
    postalCode: "",
    countryPhoneNumber: "65",
    phoneNumber: "",
    address: "",
    color: "",
    departments: [
      {
        department: "",
        jobPosition: [],
      },
    ],
  };

  const handleSubmit = (val) => {

    const locationData = {
      address: val.address,
      country: val.country,
      color: val.color,
      departments: [...val.departments],
      locationName: val.locationName,
      countryPhoneNumber: val.countryPhoneNumber,
      phoneNumber: val.phoneNumber,
      postalCode: val.postalCode,
    };

    updateLocation(locationData);
  };

  if (isPending || pendingLocation) {
    return <div>Loading...</div>;
  }

  const locationData = data?.data;

  const departments = locationData?.departments || [
    {
      department: "",
      jobPosition: "",
    },
  ];

  const setToDataApi = {
    ...initialValues,
    address: locationData?.address,
    color: locationData?.color,
    country: locationData?.country,
    locationName: locationData?.locationName,
    countryPhoneNumber: locationData?.countryPhoneNumber,
    phoneNumber: locationData?.phoneNumber,
    postalCode: locationData?.postalCode,
    departments:
      departments?.length > 0
        ? departments
        : [
            {
              department: "",
              jobPosition: "",
            },
          ],
  };

  return (
    <>
      <FormSharedLocation
        handleSubmit={handleSubmit}
        initialValues={setToDataApi}
        validationSchema={editLocationSchema}
      />
    </>
  );
}

export default EditLocation;
