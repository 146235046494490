import { useQuery } from '@tanstack/react-query'
import { customAxios } from "utils/axios";

const API = `api/payroll`;

// GET ALL PAYROLL COMPONENT
export const useGetAllPayrollComponent = ({ onSuccess, onError }) => {
    return useQuery({
        queryKey: ["getAllPayrollComponent"],
        queryFn: async () => {
            const res = await customAxios.get(`${API}/get-payroll/component`);

            return res.data;
        },
        onSuccess,
        onError
    });
}



