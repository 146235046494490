import React, { useEffect, useState } from "react";
import {
  Accordion,
  UploadImage,
  InputText,
  CountrySelect,
  InputSelect,
  Button,
  TextArea,
} from "@bluesilodev/timhutcomponents";
import { useQueryClient } from "@tanstack/react-query";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";

import { useGetMyCompanyById, useUpdateMyCompany } from "service/companyAPI";
import { useGetAllCountry } from "service/commonAPI";
import { useGetSettingByDefault } from "service/settingAPI";
import { alertSuccess, alertError } from "utils/alert";

import { FiSaveSvg } from "components/icons";
import { companyDetailSchema } from "./companyDetailSchema";
import { superAdminRole } from "utils/roleFromLocalStorage";

function SuperAdminEditCompany() {
  const { uId } = useParams();
  const [originalImg, setOriginalImg] = useState();
  const [selectedImg, setSelectedImg] = useState();
  const queryClient = useQueryClient();

  // GET ALL DATA COMPANY
  const { data, isSuccess } = useGetMyCompanyById(uId, {
    onSuccess: () => {
      queryClient.invalidateQueries("getMyCompanyById");
    },
    onError: () => {},
  });

  const initialValue = {
    uenNumber: "",
    companyName: "",
    companyImage: [],
    employeeHeadCount: "",
    industryField: "",
    country: "",
    companyCountryPhoneNumber: "65",
    companyPhoneNumber: "",
    postalCode: "",
    address: "",
  };

  useEffect(() => {
    if (isSuccess) {
      const setToApi = {
        // DEFINITION THAT DATA NOT NULL : ??
        uenNumber: data.data.uenNumber ?? "",
        companyName: data.data.companyName ?? "",
        companyImage: data.data.companyImage[0].link ?? "",
        employeeHeadCount: data.data.employeeHeadCount ?? "",
        industryField: data.data.industryField ?? "",
        country: data.data.country ?? "",
        companyCountryPhoneNumber: data.data.companyCountryPhoneNumber ?? "",
        companyPhoneNumber: data.data.companyPhoneNumber ?? "",
        postalCode: data.data.postalCode ?? "",
        address: data.data.address ?? "",
      };
      setValues(setToApi);
    }
  }, [data]);

  // UPDATE DATA COMPANY
  const { mutate, isPending: loadingUpdate } = useUpdateMyCompany({
    onSuccess: () => {
      alertSuccess("Data Success Updated");
    },
    onError: () => {
      alertError("Data Failure Updated!");
    },
  });

  const { data: resCountry, isPending } = useGetAllCountry({
    onSuccess: () => {
      queryClient.invalidateQueries("getCountry");
    },
  });

  const { data: dataSetting, isPending: loadingUpdateSetting } = useGetSettingByDefault({
      onSuccess: () => {
      },
      onError: () => {
      },
  });

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setFieldValue,
    setValues,
    errors,
    touched,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: companyDetailSchema,
    onSubmit: () => {
      const formData = new FormData();

      formData.append("uenNumber", values.uenNumber);
      formData.append("companyName", values.companyName);
      formData.append("companyImage", values.companyImage);
      formData.append("employeeHeadCount", values.employeeHeadCount);
      formData.append("industryField", values.industryField);
      formData.append("country", values.country);
      formData.append("companyCountryPhoneNumber", values.companyCountryPhoneNumber);
      formData.append("companyPhoneNumber", values.companyPhoneNumber);
      formData.append("postalCode", values.postalCode);
      formData.append("address", values.address);
      mutate({ companyID: uId, body: formData });
    },
  });

  const changeFile = (e) => {
    const file = e.currentTarget.files[0];

    if (file) {
      !originalImg && setOriginalImg(values.companyImage)
      setFieldValue("companyImage", file);
      const imageUrl = URL.createObjectURL(file);
      setSelectedImg(imageUrl);
    }
  };

  const handleDeleteImg = () => {
    if (originalImg) {
      setFieldValue("companyImage", originalImg);
      setOriginalImg();
    }
    setSelectedImg("");
  };

  if (loadingUpdate || isPending) {
    return <div>Loading Update...</div>;
  }

  const dataCountry = resCountry.data.map((item, _) => {
    return {
      label: item.name,
      value: item.country_code,
    };
  });

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit}
      className="overflow-y-auto"
    >
      {superAdminRole && (
        <div className="w-full flex justify-end pt-5">
          <Button
            style="solid"
            className={"w-[200px]"}
            type="submit"
            label={
              <div className="flex gap-2">
                <FiSaveSvg />
                <div>Save</div>
              </div>
            }
          />
        </div>
      )}

      <div className=" w-full">
        <div className="w-full py-5">
          <Accordion
            title={"Company Logo"}
            icons={[]}
            children={
              <div className="py-3">
                <UploadImage
                  id={"companyImage"}
                  onBlur={handleBlur}
                  onChange={changeFile}
                  handleDeleteImg={handleDeleteImg}
                  image={!selectedImg ? values.companyImage : selectedImg}
                  textSizeLimit={
                    "For Best Resolution is 1366 x 1366px, Max Upload image size is 2 MB, Supported files : .jpg, .png, .jpeg"
                  }
                  error={
                    errors.companyImage &&
                    touched.companyImage && (
                      <div className="text-red-500">{errors.companyImage}</div>
                    )
                  }
                  disabled={!superAdminRole && true}
                />
              </div>
            }
          />
        </div>

        <div className="pb-5">
          <Accordion
            title={"Personal Information"}
            icons={[]}
            children={
              <div className="w-full py-5 flex flex-col gap-3 px-1">
                <div className="flex gap-3 w-full">
                  <div className="w-[65%] flex gap-3">
                    <InputText
                      title={"Company Name"}
                      id={"companyName"}
                      required={true}
                      label={null}
                      value={values.companyName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!superAdminRole && true}
                      error={
                        <>
                          {errors.companyName && touched.companyName && (
                            <div className="text-red-500">
                              {errors.companyName}
                            </div>
                          )}
                        </>
                      }
                    />
                  </div>

                  <div className="w-[35%]">
                    <InputText
                      title={"UEN Number"}
                      required={true}
                      id={"uenNumber"}
                      label={null}
                      value={values.uenNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!superAdminRole && true}
                      error={
                        <div>
                          {errors.uenNumber && touched.uenNumber && (
                            <div className="text-red-500">
                              {errors.uenNumber}
                            </div>
                          )}
                        </div>
                      }
                    />
                  </div>
                </div>

                <div className="flex gap-3">
                  <div className="w-[65%] flex gap-3">
                    <InputSelect
                      title={"Employee Head Account"}
                      options={[
                        ...(dataSetting?.data?.employeeHeadCount?.map((val) => ({ label: val, value: val })) || []),
                        /*
                        { label: "0 - 10", value: "0 - 10" },
                        { label: "11 - 50", value: "11 - 50" },
                        { label: "51 - 100", value: "51 - 100" },
                        { label: "> 100", value: "> 100" },
                        */
                      ]}
                      label={null}
                      required={true}
                      type={"text"}
                      id={"employeeHeadCount"}
                      value={values.employeeHeadCount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!superAdminRole && true}
                      error={
                        <>
                          {errors.employeeHeadCount &&
                            touched.employeeHeadCount && (
                              <div className="text-red-500">
                                {errors.employeeHeadCount}
                              </div>
                            )}
                        </>
                      }
                    />
                    <InputSelect
                      title={"Which Industry best represents your company?"}
                      label={null}
                      required={true}
                      options={[
                        ...(dataSetting?.data?.companyIndustry?.map((val) => ({ label: val, value: val })) || []),
                        /*
                        {
                          label: "Technology",
                          value: "Technology",
                        },
                        {
                          label: "Finance",
                          value: "Finance",
                        },
                        {
                          label: "Healthcare",
                          value: "Healthcare",
                        },
                        {
                          label: "Retail",
                          value: "Retail",
                        },
                        {
                          label: "Manufacturing",
                          value: "Manufacturing",
                        },
                        {
                          label: "Education",
                          value: "Education",
                        },
                        {
                          label: "Government",
                          value: "Government",
                        },
                        {
                          label: "Hospitality",
                          value: "Hospitality",
                        },
                        {
                          label: "Energy",
                          value: "Energy",
                        },
                        {
                          label: "Transportation",
                          value: "Transportation",
                        },
                        {
                          label: "Others",
                          value: "Others",
                        },
                        */
                      ]}
                      id={"industryField"}
                      value={values.industryField}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!superAdminRole && true}
                      error={
                        <div>
                          {errors.industryField && touched.industryField && (
                            <div className="text-red-500">
                              {errors.industryField}
                            </div>
                          )}
                        </div>
                      }
                    />
                  </div>
                  <div className="w-[35%]">
                    <InputSelect
                      title={"Country"}
                      label={null}
                      options={dataCountry}
                      required={true}
                      id={"country"}
                      value={values.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!superAdminRole && true}
                      error={
                        <div>
                          {errors.country && touched.country && (
                            <div className="text-red-500">{errors.country}</div>
                          )}
                        </div>
                      }
                    />
                  </div>
                </div>

                <div className="flex gap-3">
                  <div className="w-[65%] flex gap-3">
                    <CountrySelect
                      isTitle={false}
                      classname={""}
                      onChange={(value) => setFieldValue("companyCountryPhoneNumber", value.code)}
                      value={`${values.companyCountryPhoneNumber}`}
                      disabled={!superAdminRole && true}
                      error={
                        <div>
                          {errors.companyCountryPhoneNumber &&
                            touched.companyCountryPhoneNumber &&
                            errors.companyCountryPhoneNumber}
                        </div>
                      }
                    />
                    <InputText
                      title={"Phone Number"}
                      label={null}
                      type={"number"}
                      required={true}
                      id={"companyPhoneNumber"}
                      value={values.companyPhoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!superAdminRole && true}
                      error={
                        <div>
                          {errors.companyPhoneNumber && touched.companyPhoneNumber && (
                            <div className="text-red-500">
                              {errors.companyPhoneNumber}
                            </div>
                          )}
                        </div>
                      }
                    />
                  </div>
                  <div className="w-[35%]">
                    <InputText
                      title={"Postal Code"}
                      type={"number"}
                      required={true}
                      label={null}
                      id={"postalCode"}
                      value={values.postalCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!superAdminRole && true}
                      error={
                        <div>
                          {errors.postalCode && touched.postalCode && (
                            <div className="text-red-500">
                              {errors.postalCode}
                            </div>
                          )}
                        </div>
                      }
                    />
                  </div>
                </div>
                <div className="w-[65%]">
                  <textarea
                    id="address"
                    className={`w-full border-[1px] border-black resize-none h-[150px] p-3 rounded-md outline-none ${!superAdminRole && "bg-[#F2F4F5] border-0"}`}
                    placeholder="address"
                    value={values.address}
                    onChange={handleChange}
                    disabled={!superAdminRole && true}
                    onBlur={handleBlur}
                  ></textarea>
                  {errors.address && touched.address && (
                    <div className="text-red-500"> {errors.address} </div>
                  )}
                </div>
              </div>
            }
          />
        </div>
      </div>
    </form>
  );
}

export default SuperAdminEditCompany;
