import React, { useState } from "react";
import { Accordion, InputSelect } from "@bluesilodev/timhutcomponents";
import { useFormikContext } from "formik";
import "flag-icons/css/flag-icons.min.css";


import {
  Address,
  Education,
  Hobbies,
  Languages,
  PersonalInformation,
  ProfilePicture,
  Skills,
  UserCredential,
} from "pages/shared/formUser/personalInfo";
import { useGetAllCompany } from "service/companyAPI";

function SuperAdminPersonalInfo({ countries }) {
  const [formikVal, setFormikVal] = useState([]);

  const {
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const { data, isSuccess, refetch } = useGetAllCompany({ keyword: "" }, {
      onSuccess: () => {
      },
      onError: (err) => {
          console.log("err: ", err);
      },
  });

  return (
    <div className="w-full flex flex-col gap-4 overflow-y-auto h-max py-5">
      <div className="w-full rounded-md ">
        <Accordion
          icons={[]}
          title={"Company"}
          children={
            <div className="w-full flex flex-wrap gap-3 pt-3">
              <div className="flex gap-3 w-1/3">
                <InputSelect
                  options={[
                    ...(data?.data?.map((val) => ({ label: val.companyName, value: val.uId })) || [])
                  ]}
                  required={true}
                  title={"Company"}
                  classname={"w-full"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.organizationID}
                  id={"organizationID"}
                  error={
                    <div>
                      {errors.organizationID &&
                        touched.organizationID &&
                        errors.organizationID}
                    </div>
                  }
                />
              </div>
            </div>
          }
        />
      </div>
      <ProfilePicture
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        fieldPhoto={"photo"}
      />
      <PersonalInformation
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
      />
      <UserCredential
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <Education formikVal={formikVal} setFormikVal={setFormikVal} />
      <Address
        values={values}
        countries={countries}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
      />
      <Hobbies
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />
      <Skills
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />
      <Languages
        values={values}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        setFieldTouched={setFieldTouched}
      />
    </div>
  );
}

export default SuperAdminPersonalInfo;
