import React, { useEffect, useState } from "react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { settingSchema } from "./settingSchema";
import { useEditSetting, useGetSettingByOrganizationId } from "service/settingAPI";
import { alertError, alertSuccess } from "utils/alert";
import FormSetting from "pages/shared/formSetting";
import { adminRole } from "utils/roleFromLocalStorage";
import { useSelector } from "react-redux";

function AdminSetting() {
    const { currentUser } = useSelector((state) => state.loginSlice);
    // GET DATA SETTING
    const { data, isSuccess } = useGetSettingByOrganizationId(currentUser.organizationID || "", {
        onSuccess: () => {},
        onError: () => {},
    });
    // UPDATE DATA SETTING
    const { mutate, isPending: loadingUpdate } = useEditSetting({
        onSuccess: () => {
            alertSuccess("Data Success Updated");
        },
        onError: () => {
            alertError("Data Failure Updated!");
        },
    });
    const initialValue = {
        uId: "",
        type: "",
        organizationID: "",
        hobbies: [],
        skills: [],
        typeOfAllowances: [],
        isActive: true,
    };
    const formik = useFormik({
        initialValues: initialValue,
        validationSchema: settingSchema,
        onSubmit: () => {
            const formData = {};
            formData.organizationID = values.organizationID;
            formData.hobbies = values.hobbies;
            formData.skills = values.skills;
            formData.typeOfAllowances = values.typeOfAllowances;
            formData.isActive = values.isActive;
            mutate(formData);
        },
    });
    useEffect(() => {
        if (isSuccess) {
            const setToApi = {
                uId: data.data.uId ?? "",
                type: data.data.type ?? "",
                organizationID: data.data.organizationID ?? "",
                hobbies: data.data.hobbies ?? [],
                skills: data.data.skills ?? [],
                typeOfAllowances: data.data.typeOfAllowances ?? [],
                isActive: data.data.isActive ?? true,
            };
            setValues(setToApi);
        }
    }, [data]);
    const {
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm,
        setFieldValue,
        setFieldTouched,
        setValues,
        errors,
        touched,
    } = formik;
    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <FormSetting
                    errors={errors}
                    handleSubmit={handleSubmit}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    touched={touched}
                    values={values}
                    disabled={!adminRole && true}
                />
            </FormikProvider>
        </React.Fragment>
    )
}

export default AdminSetting;
