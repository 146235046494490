import React from "react";
import { Accordion, InputSelect } from "@bluesilodev/timhutcomponents";
import SelectChoice from "components/SelectChoice";
import { useGetSettingByDefault } from "service/settingAPI";

function Hobbies({ values, setFieldTouched, errors, setFieldValue, touched }) {
  const { data, isPending: loadingUpdate } = useGetSettingByDefault({
      onSuccess: () => {
      },
      onError: () => {
      },
  });

  const handleDelete = (positionIndex, setFieldValue, value) => {
    const data = value.filter((item, idx) => idx !== positionIndex);
    setFieldValue(`hobbies`, data);
  };

  return (
    <div className="w-full">
      <Accordion
        title={"Hobbies"}
        icons={[]}
        children={
          <div className="flex gap-3 w-full pt-3">
            <div className="w-full">
              <InputSelect
                title={"Hobbies"}
                options={[
                  ...(data?.data?.hobbies?.map((val) => ({ label: val, value: val })) || []),
                  /*
                  { label: "Blogging", value: "Blogging" },
                  { label: "Photography", value: "Photography" },
                  { label: "Gaming", value: "Gaming" },
                  { label: "Streaming Video", value: "Streaming Video" },
                  { label: "Cycling", value: "Cycling" },
                  { label: "Reading", value: "Reading" },
                  { label: "Football", value: "Football" },
                  { label: "Basketball", value: "Basketball" },
                  { label: "Food", value: "Food" },
                  { label: "Cooking", value: "Cooking" },
                  { label: "DIY Projects", value: "DIY Projects" },
                  { label: "Music", value: "Music" },
                  { label: "Social Media", value: "Social Media" },
                  { label: "Fitness", value: "Fitness" },
                  { label: "Learning Languages", value: "Learning Languages" },
                  { label: "Crafting", value: "Crafting" },
                  { label: "Gardening", value: "Gardening" },
                  { label: "Podcasting", value: "Podcasting" },
                  { label: "Traveling", value: "Traveling" },
                  { label: "Painting", value: "Painting" },
                  { label: "Writing", value: "Writing" },
                  { label: "Online Shopping", value: "Online Shopping" },
                  { label: "Coding", value: "Coding" },
                  { label: "Virtual Reality", value: "Virtual Reality" },
                  { label: "Meditation", value: "Meditation" },
                  { label: "Graphic Design", value: "Graphic Design" },
                  { label: "Investing", value: "Investing" },
                  { label: "Others", value: "Others" },
                  */
                ]}
                id={`hobbies`}
                value={values.hobbies[0] || ""}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  const updateHobbies = [...values.hobbies];
                  if (
                    !updateHobbies.includes(selectedValue) &&
                    selectedValue.length !== 0
                  ) {
                    // Periksa apakah nilai sudah ada dalam array
                    updateHobbies.push(selectedValue);
                    setFieldValue(`hobbies`, updateHobbies);
                  }
                }}
                onBlur={() => setFieldTouched(`hobbies`, true)}
                error={
                  errors.hobbies &&
                  touched.hobbies && (
                    <div className="text-red-500">{errors.hobbies}</div>
                  )
                }
              />
              <div className="flex gap-2 mt-2">
                {/* Mapping value Hobbies */}
                {values.hobbies.map((position, positionIndex) => (
                  <div className="" key={positionIndex}>
                    <SelectChoice
                      title={position}
                      handleDelete={() => {
                        const value = values.hobbies;
                        handleDelete(positionIndex, setFieldValue, value);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default Hobbies;
