import { useQuery, useMutation } from '@tanstack/react-query'
import { customAxios } from "utils/axios";

const API = `/api/location`;

export const useSuperAdminGetAllLocation = (organizationID, option) => {
    return useQuery({
        queryKey: ["getLocation", organizationID],
        queryFn: async () => {
            const res = await customAxios.get(`${API}/superAdmin/${organizationID}`);
            return res.data;
        },
        ...option
    });
}

export const useGetAllLocation = (options) => {

    const { keyword, ...queryOptions } = options;

    const fetchData = async () => {
        let queryParams = "";
        // Mengecek apakah parameter tidak kosong, jika kosong, tidak ditambahkan ke URL
        if (keyword) queryParams += `keyword=${keyword}&`;

        if (queryParams.endsWith('&')) {
            queryParams = queryParams.slice(0, -1);
        }

        const url = queryParams ? `${API}?${queryParams}` : API;

        try {
            const res = await customAxios.get(url);

            return res.data;
        } catch (error) {
            console.error("Error fetching data: ", error); // Debug: Log error
            throw error;
        }
    }

    const queryKey = ["getLocation"];
    if (keyword) queryKey.push(keyword);


    return useQuery({
        queryKey: queryKey.length > 1 ? queryKey : ["getLocation"], // Gunakan queryKey yang sudah dibuat
        queryFn: fetchData,
        ...queryOptions
    });
}

export const useGetLocationById = (uId, { onSuccess, onError }) => {
    return useQuery({
        queryKey: ["getLocationById", uId],
        queryFn: async () => {
            const res = await customAxios.get(`${API}/${uId}/`)

            return res.data;
        },
        onSuccess,
        onError
    })
}

export const useCreateLocation = ({ onSuccess, onError }) => {
    return useMutation({
        mutationFn: async (body) => {
            const res = await customAxios.post(`${API}`, body)

            return res.data;
        },
        onSuccess,
        onError
    });
};

export const useDeleteLocation = (uId, { onSuccess, onError }) => {
    return useMutation({
        mutationFn: async () => {
            const res = await customAxios.delete(`${API}/${uId}`)

            return res;
        },
        onSuccess,
        onError
    });
};

export const useEditLocation = (uId, { onSuccess, onError }) => {
    return useMutation({
        mutationFn: async (body) => {

            const res = await customAxios.put(`${API}/${uId}`, body)

            return res.data;
        },
        onSuccess,
        onError
    });
};

