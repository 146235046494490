import { jwtDecode } from "jwt-decode";

export const payload = JSON.parse(localStorage.getItem("currentUser")) || "";

export const accessToken = localStorage.getItem("accessToken") || {}

export const userLoginNow = accessToken && accessToken !== "null" && jwtDecode(accessToken)

export const organizationID = payload?.organizationID

// Memeriksa peran Supervisor atau Admin dengan aman
export const superAdminRole = Array.isArray(payload?.role) ?
    payload?.role.includes("SuperAdmin") : null

export const adminAndSuperVisorRole = Array.isArray(payload?.role) ?
    payload?.role.some((role) => ["Supervisor", "Admin"].includes(role)) : null

// Memeriksa peran Admin dengan aman
export const adminRole = Array.isArray(payload?.role) ?
    payload?.role.includes("Admin") : null

// Memeriksa peran Supervisor dengan aman
export const supervisorRole = Array.isArray(payload?.role) ?
    payload?.role.includes("Supervisor") : null
