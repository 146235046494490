import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { useCreateLocation } from "service/locationAPI";

import FormSharedLocation from "pages/shared/formLocation";
import { alertSuccess, alertError } from "utils/alert";
import { payload } from "utils/roleFromLocalStorage";

import { locationSchema } from "./locationSchema";

function CreateLocation() {
  const organizationID = payload && payload?.payLoad?.organizationID;

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const initialValues = {
    locationName: "",
    country: "",
    postalCode: "",
    countryPhoneNumber: "65",
    phoneNumber: "",
    address: "",
    color: "#f11111",
    departments: [
      {
        department: "",
        jobPosition: [],
      },
    ],
  };

  const { mutate, isPending } = useCreateLocation({
    onSuccess: () => {
      queryClient.invalidateQueries("getLocation");
      alertSuccess("Create Location Success!");
      navigate("/location");
    },
    onError: () => {
      alertError("Something Wrong!");
    },
  });

  if (isPending) {
    return <div>Loading...</div>;
  }

  const handleSubmit = (val, { resetForm }) => {
    const locationData = {
      organizationID: organizationID,
      address: val.address,
      country: val.country,
      color: val.color,
      departments: [...val.departments],
      locationName: val.locationName,
      countryPhoneNumber: val.countryPhoneNumber,
      phoneNumber: val.phoneNumber,
      postalCode: val.postalCode,
    };

    mutate(locationData);
    resetForm();
  };

  return (
    <FormSharedLocation
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={locationSchema}
    />
  );
}

export default CreateLocation;
